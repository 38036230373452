@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Sofia';
  font-weight: 100;
  src: url('./assets/fonts/sofia-pro/SofiaProUltraLight.woff2') format('woff2');
}

@font-face {
  font-family: 'Sofia';
  font-weight: 200;
  src: url('./assets/fonts/sofia-pro/SofiaProExtraLight.woff2') format('woff2');
}

@font-face {
  font-family: 'Sofia';
  font-weight: 300;
  src: url('./assets/fonts/sofia-pro/SofiaProLight.woff2') format('woff2');
}

@font-face {
  font-family: 'Sofia';
  font-weight: 400;
  src: url('./assets/fonts/sofia-pro/SofiaProRegular.woff2') format('woff2');
}

@font-face {
  font-family: 'Sofia';
  font-weight: 500;
  src: url('./assets/fonts/sofia-pro/SofiaProMedium.woff2') format('woff2');
}

@font-face {
  font-family: 'Sofia';
  font-weight: 600;
  src: url('./assets/fonts/sofia-pro/SofiaProSemiBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Sofia';
  font-weight: 700;
  src: url('./assets/fonts/sofia-pro/SofiaProBold.woff2') format('woff2');
}

@layer base {
  html {
    font-family: 'Sofia', system-ui, sans-serif;
  }
}

:root {
  --toastify-toast-width: 400px !important;
}

::-webkit-scrollbar {
  @apply w-0 bg-secondary rounded-md;
}

::-webkit-scrollbar-track {
  @apply w-0 bg-secondary rounded-md;
}

::-webkit-scrollbar-thumb {
  @apply w-0 bg-primary rounded-md;
}

:root {
  background-color: #ffe2f5;
}

.checkbox-custom {
  flex-shrink: 0;
  height: 0.8rem;
  width: 0.8rem;
  cursor: pointer;
  appearance: none;
  border-width: 1px;
}

.select-input {
  background: url('img/expand-down.png') no-repeat right 1.2rem center;
}

.select-input:focus {
  background: url('img/expand-up.png') no-repeat right 1.2rem center !important;
}

.select-input {
  border: 0.8px solid #D497F580;
}

.select-input:focus {
  border: 1.8px solid #D497F580;
}

/* hide native expand btn */
select::-ms-expand {
  display: none;
}

input:focus-visible {
  /* outline: none !important; */
  outline-color: #dd9dff !important;
}

.select-input {
  background-color: white;
}

.select-input option {
  font-size: 1rem;
  font-weight: 500;
}

.select-input option:checked {
  background-color: #fff5e4 !important;
  margin: 1rem 2rem !important;
}

.select-input option:hover {
  color: yellow !important;
}

.step-three-check input[type='checkbox'] {
  filter: invert(70%) sepia(79%) saturate(408%) hue-rotate(219deg) brightness(140%) contrast(101%);
}

/* custom select styling */
.tellit-select>div:first-of-type {
  border-radius: 20px;
  border: 1px solid #D497F580 !important;
  text-align: center;
  font-size: 18px;
  padding-left: 3rem;
  color: #0d002b;
  font-weight: 400;
}

.tellit-select .t-select__indicator-separator {
  display: none !important;
}

.tellit-select .t-select__indicator {
  padding-right: 1.5rem;
}

.tellit-select .t-select__control {
  box-shadow: none !important;
  height: 45px;
  border-radius: 36px !important;
}

.tellit-select .t-select__menu {
  border-radius: 30px;
  background-color: white;
  border: 1px solid #D497F580;
  padding: 0.3rem 1rem;
}

.tellit-select>div:nth-of-type(2) div {
  border-radius: 20px;
  text-align: center;
  font-size: 18px;
  line-height: 35px;
  color: #0d002b;
  font-weight: 400;
  padding: 0.3rem 1rem;
  margin: 0.3rem 0;
}

.tellit-select>div:nth-of-type(2) div div:hover {
  background-color: #fff5e4;
}

.tellit-select .t-select__option--is-focused {
  background-color: #fff5e4;
}

.tellit-select .t-select__option--is-selected {
  background-color: #fff5e4;
  color: #0d002b;
}

.btn-sm-text span {
  @apply text-white
}

/* lap top only */
@media (resolution: 1.25dppx) {
  .card-125 {
    zoom: 0.85;
  }

  .step-wrapper {
    /* margin-left: 2rem !important;
    margin-right: 2rem !important; */
  }

  .top-p {
    padding-top: 6rem !important;
  }

  .margin-inline {
    margin-inline: 2rem !important;
  }

  .step-four-input {
    min-width: 16rem !important;
  }

  .step-four-wrapper {
    margin-inline: 0rem !important;
  }
}

/* very small */
@media screen and (min-width: 240px) and (max-width: 419px) {
  .step-5-card-btn {
    min-width: 13rem !important;
    width: 13em !important;
  }

  .step-3-card-btn-parent {
    flex-direction: column !important;
  }

  .input-label {
    font-size: 0.7rem !important;
  }

  .s-mobile-text {
    font-size: 0.7rem !important;
    text-align: center !important;
  }

  .t-select__control {
    padding-left: 0.2rem !important;
  }

  .t-select__menu {
    padding: 0rem !important;
  }

  .t-select__option {
    padding: 0.3rem 0rem !important;
  }

  .t-select__placeholder {
    font-size: 0.7rem !important;
  }

  .accept-btn {
    font-size: 0.7rem !important;
    font-weight: 700;
  }

  .input-width {
    width: 200px !important;
  }

  .step-5-btn {
    width: 230px !important;
  }

  .step-5-btn span {
    font-size: 0.9rem;
  }

  .btn-sm-text span {
    font-size: 0.8rem !important;

  }

  .step-3-card-btn-parent button {
    min-width: 200px;
  }
}

/* mobile and tab */
@media screen and (min-width: 420px) and (max-width: 768px) {
  .t-select__control {
    padding-left: 0.5rem !important;
  }

  .t-select__placeholder {
    font-size: 0.8rem !important;
    width: max-content;
  }

  .step-5-card-btn {
    min-width: 14rem !important;
    width: 14rem !important;
  }

  .input-width {
    width: 210px !important;
  }

  .step-5-btn {
    width: 250px !important;
  }

  .step-5-btn span {
    font-size: 0.9rem;
  }
}

/* tab and mini desktop */
@media screen and (min-width: 769px) and (max-width: 1023px) {
  .t-select__control {
    padding-left: 0.5rem !important;
  }

  .t-select__placeholder {
    font-size: 0.8rem !important;
    width: max-content;
  }

  .card-side-wrapper {
    justify-content: center !important;
  }

  .step-5-btn {
    width: 230px !important;
  }


  .step-5-btn span {
    font-size: 0.9rem;
  }

  .input-width {
    width: 210px !important;
  }

  .step-5-btn span {
    font-size: 0.9rem;
  }

  .step-5-footer {
    gap: 60px !important;
    justify-content: center !important;
  }

  .step-6-footer {
    gap: 40px !important;
    justify-content: center !important;
  }
}

/* large tab and 1024 screens desktop */
@media screen and (min-width: 1024px) and (max-width: 1280px) {
  .t-select__control {
    padding-left: 0.5rem !important;
  }

  .t-select__placeholder {
    font-size: 0.8rem !important;
    width: max-content;
  }

  .step-5-footer {
    justify-content: center !important;
  }

  .step-5-card-btn {
    min-width: 12rem !important;
    width: 12rem !important;
  }

  .step-5-card-btn span {
    font-size: 0.7rem !important;
  }

  .step-6-footer {
    margin-top: 5rem;
  }

  .step-6-footer .accept-btn {
    font-size: 0.8rem !important;
    padding-inline: 0.7rem !important;
  }

  .left-subtitle {
    padding-top: 3rem;
  }

  .input-width {
    width: 210px !important;
  }

  .step-5-btn {
    width: 180px !important;
    gap: 0px !important;
  }

  .step-5-btn div svg {
    font-size: 20px !important;
  }

  .step-5-btn span {
    font-size: 0.7rem;
  }

  .step-6-margin {
    margin-left: 10px !important;
    margin-left: 10px !important;
  }

  .step-6-footer {
    padding-inline: 10px !important;
  }

  .step-6-footer :first-child {
    padding-inline: 0px !important;
    min-width: 1rem !important;
  }
}

@media screen and (min-width: 1281px) and (max-width: 1399px) {
  .t-select__control {
    padding-left: 0.5rem !important;
  }

  .t-select__placeholder {
    font-size: 1rem !important;
    width: max-content;
  }

  .left-subtitle {
    padding-top: 3rem;
  }

  .input-width {
    width: 228px !important;
  }

  .step-5-btn {
    width: 260px !important;
  }

  .step-5-btn span {
    font-size: 0.9rem;
  }
}

/* medium screen */
@media screen and (min-width: 1400px) {
  .t-select__control {
    padding-left: 0.5rem !important;
  }

  .t-select__placeholder {
    font-size: 1rem !important;
    width: max-content;
  }

  .step-footer {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .step-5-card-parent {
    justify-content: center !important;
  }

  .step-5-card-btn {
    max-width: 15rem !important;
  }

  .step-6-footer {
    margin-top: 4rem;
  }

  .left-subtitle {
    padding-top: 3rem;
    line-height: 50px !important;
  }

  .input-width {
    width: 238px !important;
  }

  .step-5-btn {
    width: 290px !important;
  }
}

@media screen and (min-width: 1480px) and (max-width: 1599px) {
  .t-select__control {
    padding-left: 0.5rem !important;
  }

  .t-select__placeholder {
    font-size: 1rem !important;
    width: max-content;
  }

  .back-btn {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
    min-width: 5rem !important;
  }

  .card-min-width {
    min-width: 610px !important;
  }

  .input-width {
    width: 238px !important;
  }

  .step-5-btn {
    width: 300px !important;
  }

  .step-5-footer {
    gap: 60px !important;
  }

  .step-6-margin {
    margin-left: 50px !important;
    margin-left: 50px !important;
  }

  .step-6-footer {
    padding-inline: 50px !important;
  }
}


@media screen and (min-width: 1536px) and (max-width: 1599px) {

  .footer-nav {
    @apply !px-sp100
  }

  .lg-space {
    @apply !px-sp50
  }
}

@media screen and (min-width: 1600px) and (max-width: 1899px) {
  .card-min-width {
    min-width: 610px;
  }

}

/* large */
@media screen and (min-width: 1900px) {

  .t-select__control {
    padding-left: 60px !important;
  }

  .t-select__placeholder {
    font-size: 18px !important;
    width: max-content;
  }

  .step-footer {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }

  .step-6-footer {
    margin-top: 0rem;
  }

  .input-width {
    @apply w-sp340 !important;
  }
}

/* aspect ratio */

@media (min-aspect-ratio: 21/9) and (min-width:2180px) {
  .header-container {
    align-items: flex-end !important;
  }

  .header-container h4 {
    padding-right: 19.5rem;
  }
}

/* width fill only for firefox */

@supports (-moz-appearance: none) {
  .w-fill {
    width: 100% !important;
    width: -moz-available !important;
    width: -webkit-fill-available !important;
    /* width: fill-available !important; */
  }
}